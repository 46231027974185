<template>
  <label class="file-select">
    <slot>
      <span class="pseudo-link-blue">Select a File</span>
    </slot>

    <div class="top-5 red-text semibold-weight size-text-11px" v-if="hasErrors">
      * {{ errorDisplay }}
    </div>

    <input ref="fileInput" type="file" :accept="accept" :multiple="multiple" @change="handleFileChange">
  </label>
</template>

<script>
import { each } from 'lodash';

export default {
  name: 'FileSelector',

  props: {
    accept: {
      type: String,
      default: ''
    },

    multiple: {
      type: Boolean,
      default: false
    },

    maxSize: { // in MB
      type: Number,
      default: 0
    }
  },

  data: () => {
    return {
      errors: []
    };
  },

  computed: {
    hasErrors() {
      return this.errors.length > 0;
    },

    errorDisplay() {
      return this.errors.join('; ');
    },

    shouldValidateMaxSize() {
      return (this.maxSize || 0) > 0;
    },

    maxSizeBytes() {
      // Convert MB to bytes
      return Math.round(this.maxSize * Math.pow(1024, 2));
    }
  },

  methods: {
    reset() {
      this.$refs.fileInput.value = '';
    },

    validate(files) {
      this.errors = [];

      if (this.shouldValidateMaxSize) {
        each(files, (file) => {
          if (!this.maxSizeIsValid(file.size)) {
            this.errors.push(`File "${file.name}" is larger than the limit of ${this.maxSize}MB`);
          }
        });
      }

      return !this.errors.length;
    },

    maxSizeIsValid(fileSize) {
      return fileSize <= this.maxSizeBytes;
    },

    handleFileChange($event) {
      const files = $event.target.files;

      if (files.length && this.validate(files)) {
        this.$emit('change', files);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .file-select {
    margin: 0;
    font-weight: 400;

    &.dash-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;
      width: 100%;
      text-align: center;
      border: 2px dashed $k-gray;
    }

    input[type="file"] {
      display: none;
    }
  }
</style>
