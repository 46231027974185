import Message from 'resources/marketplace/message.js';
import Conversation from 'resources/marketplace/conversation.js';
import { get, without } from 'lodash';

class MessagingService {
  constructor() {
    this.unreadCount = 0;
    this.conversations = [];
    this.selectedLoaders = [];
    this.conversations = [];
    this.selectedConversation = null;

    // temporary attribute until lawyer app converted to Vue
    // see setAngularWatcher below
    this.ngAppointmentWatcher = null;

    this.loaders = {
      'unreadCount': this.loadUnreadCount,
      'conversations': this.loadConversations,
      'loadConversation': this.loadConversation,
      'createOrUpdateMessage': this.createOrUpdateMessage
    };

    this.userClass = 'admin';
    this.addLoader('unreadCount');
  }

  setUserClass(userClass) {
    this.userClass = userClass;
  }

  addLoader(loaderLabel) {
    const loader = this.loaders[loaderLabel];
    if (this.selectedLoaders.includes(loader)) { return; }

    this.selectedLoaders.push(loader);
  }

  removeLoader(loaderLabel) {
    this.selectedLoaders = without(this.selectedLoaders, this.loaders[loaderLabel]);
  }

  // temporary hack to allow "broadcasting" to an Angular controller
  // Once the lawyer app is converted to Vue, it can listen to pinia without
  // having to pass an explicit store
  setAngularWatcher(store) {
    this.ngAppointmentWatcher = store;
  }

  loadUnreadCount() {
    return Message.fetchUnreadCount().then((result) => {
      this.unreadCount = result.unreadCount;
    });
  }

  loadConversations() {
    return Conversation.query().then((conversations) => {
      conversations.forEach(c => c.setParticipantDisplaysFor(this.userClass));
      this.conversations = conversations;
    });
  }

  loadConversation(conversation) {
    conversation = conversation || this.selectedConversation;
    this.setConversation(conversation);

    return Conversation.get({ id: conversation.id }).then((response) => {
      conversation.isUnread = false;
      this.setConversation(response);
      return response;
    });
  }

  createOrUpdateMessage(sending = false) {
    if (sending) { this.selectedConversation.draftMessage.draft = false; }

    const possibleLawyerId = get(this.lastReceivedMessage(), 'message.possibleLawyerId', null);
    const params = {
      id: this.selectedConversation.draftMessage.id,
      conversationId: this.selectedConversation.id,
      recipients: this.selectedConversation.formattedRecipients,
      message: this.selectedConversation.draftMessage,
      attachments: (this.selectedConversation.files || []).map(f => f.id).join(','),
      possibleLawyerId: possibleLawyerId,
      answeringQuestion: possibleLawyerId !== null
    };

    return Message.save(params).then((receipt) => {
      if (this.selectedConversation.draftMessage.id && receipt.message.id !== this.selectedConversation.draftMessage.id) {
        this.loadConversation(this.findConversation(receipt.message.conversationId));
      }
      else if (this.selectedConversation) {
        if (sending) { this.selectedConversation.lastMessageSentAt = receipt.message.updatedAt; }
        this._afterCreateOrUpdateMessage(receipt, this.selectedConversation);
      }
    });
  }

  lastReceivedMessage() {
    const receivedMessages = this.selectedConversation.receipts.filter(r => r.mailboxType === 'inbox');
    return receivedMessages[receivedMessages.length - 1];
  }

  setConversation(conversation) {
    conversation.setParticipantDisplaysFor(this.userClass);
    this.selectedConversation = conversation;
  }

  setRead(conversation) {
    conversation.isUnread = false;
  }

  findConversation(id) {
    return this.conversations.find(c => c.id === id);
  }

  unloadConversation() {
    this.selectedConversation = null;
  }

  archiveConversation() {
    return Conversation.delete({ id: this.selectedConversation.id }).then(() => {
      const conversation = this.findConversation(this.selectedConversation.id);
      this.conversations.splice(this.conversations.indexOf(conversation), 1);
      this.unloadConversation();
    });
  }

  findOrCreateConversation(conversation) {
    const recipients = conversation.recipients.map(r => r.postId).join(',');

    return Conversation.save({ recipients: recipients }).then((res) => {
      this.setConversation(res);
      if (this.findConversation(res.id)) { return; }
      this.conversations.push(this.selectedConversation);
    });
  }

  refresh() {
    if (this.selectedConversation) {
      this.addLoader('createOrUpdateMessage');
      this.addLoader('loadConversation');
    }
    else {
      this.removeLoader('createOrUpdateMessage');
      this.removeLoader('loadConversation');
    }

    // use `bind` since the detachment of the functions in loaders() ends
    // up invoking functions without the messaging service as a context
    return Promise.all(this.selectedLoaders.map(loader => loader.bind(this)()));
  }

  createMessageFromModal(message, recipients, possibleLawyerId, counselRequestId) {
    const params = {
      message: message,
      recipients: recipients.map(r => `${r.klass}-${r.id}`).join(','),
      attachments: message.attachments.map(a => a.id).join(','),
      possibleLawyerId: possibleLawyerId,
      counselRequestId: counselRequestId
    };

    return Message.save(params).then((receipt) => {
      let conversation = this.findConversation(receipt.message.conversationId);

      if (!conversation) {
        conversation = new Conversation({
          id: receipt.message.conversationId,
          receipts: [receipt],
          participants: recipients
        });

        conversation.setParticipantDisplaysFor(this.userClass);
      }

      this._afterCreateOrUpdateMessage(receipt, conversation);
    });
  }

  _afterCreateOrUpdateMessage(receipt, conversation) {
    const existingConversation = this.findConversation(conversation.id);

    if (conversation && this.selectedConversation && (conversation.id === this.selectedConversation.id)) {
      conversation.setMostRecentReceipt(receipt);
    }

    if (receipt.message.draft) {
      conversation.draftSubject = receipt.message.body.substr(0, 256);
    }
    else {
      if (this.ngAppointmentWatcher) { this.ngAppointmentWatcher.triggerUpdate(); }
      conversation.subject = receipt.message.body.substr(0, 256);
    }

    if (existingConversation) {
      this.conversations.splice(this.conversations.indexOf(existingConversation), 1, conversation);
    }
    else {
      this.conversations.push(conversation);
    }
  }
}

export default new MessagingService();
