<template>
  <div class="shadowed-box settings-box">
    <div class="box-header text-uppercase">
      Law Firm Tags
    </div>

    <div class="box-content">
      <div class="size-text-14px bold-weight bottom-5">
        View/Manage Law Firm Tags
      </div>

      <div class="dark-gray-text">
        Admin users can view and manage descriptive law firm tags here. Use the edit functionality below to create and edit law firm tag names and assign law firm tag colors. Use the drag and drop functionality to prioritize the order in which the law firm tags appear on law firm and associated lawyer profiles. Administrators can assign law firm tags to the applicable law firm on the Law Firm Status page.
      </div>

      <div class="top-10">
        <hr class="no-margin">
      </div>

      <loading-section name="scoutTags">
        <div class="droppable-scout-tag" v-droppable="{ id: null }" @on-drop="reorderScoutTags"></div>
        <div
          v-for="tag in scoutTagsCopy"
          :key="tag.id"
          v-droppable="{ id: tag.id }"
          v-draggable="{ id: tag.id }"
          class="draggable-scout-tag"
          @on-drop="reorderScoutTags">
          <scout-tag-editing-row
            :scout-tag="tag"
            :scout-tags="scoutTags"
            :on-saved="loadScoutTags"
            :on-delete="loadScoutTags">
          </scout-tag-editing-row>
        </div>
      </loading-section>

      <div v-if="!hasTags" class="missing-text">
        No tags added
      </div>

      <div class="top-20">
        <scout-tag-editing-modal :scout-tag="scoutTag" :scout-tags="scoutTags" :on-saved="loadScoutTags" v-if="canAddScoutTag"></scout-tag-editing-modal>

        <div v-else>
          <!-- TODO: Once PRIOR-1408 has merged replate this block with the new interface note component https://github.com/priori-legal/priori_legal/blob/PRIOR-1408/app/javascript/src/vue-app/shared/components/interface-note.vue -->
          <div class="yellow-message-box">
            <svg-icon name="exclamation" class="base-icon warning-icon right-10"></svg-icon>

            <span class="message">
              Note: You have reached the maximum number of Law Firm Tags. You can use the functionality above to edit and delete existing Law Firm Tags. If you have any other questions, please reach out to your Priori customer success representative.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ScoutTagEditingRow from 'vue-app/scout/client/settings/scout-tag-editing-row.vue';
import ScoutTagEditingModal from 'vue-app/scout/client/settings/scout-tag-editing-modal.vue';
import Draggable from 'vue-app/shared/directives/draggable.js';
import Droppable from 'vue-app/shared/directives/droppable.js';
import { cloneDeep, pull } from 'lodash';

export default {
  name: 'ScoutTagEditingPanel',

  directives: {
    Draggable,
    Droppable
  },

  components: {
    LoadingSection,
    ScoutTagEditingRow,
    ScoutTagEditingModal
  },

  props: {
    scoutTags: {
      type: Array,
      required: true
    },

    loadScoutTags: {
      type: Function,
      required: true
    },

    onOrderUpdated: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      scoutTag: {},
      maxScoutTagsAllowed: 50,
      scoutTagsCopy: cloneDeep(this.scoutTags)
    };
  },

  computed: {
    hasTags() {
      return this.scoutTags.length > 0;
    },

    canAddScoutTag() {
      return this.scoutTags.length < this.maxScoutTagsAllowed;
    }
  },

  watch: {
    scoutTags() {
      this.scoutTagsCopy = cloneDeep(this.scoutTags);
    }
  },

  methods: {
    reorderScoutTags(evt) {
      const draggedId = evt.detail.draggedId;
      const droppedId = evt.detail.droppedId;
      const order = this.scoutTagsCopy.map((scoutTag) => scoutTag.id);

      if (draggedId === droppedId) { return; }
      if (order.indexOf(draggedId) === 0 && isNaN(droppedId)) { return; }

      pull(order, draggedId);

      order.splice(order.indexOf(droppedId) + 1, 0, draggedId);

      const scoutTags = order.map((scoutTagId, index) => {
        return { id: scoutTagId, position: index + 1 };
      });

      this.onOrderUpdated(scoutTags);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .yellow-message-box .message {
    font-weight: normal;
  }

  .draggable-scout-tag {
    margin-bottom: 10px;

    &:hover {
      cursor: grab;
    }

    &.dragging {
      opacity: .4;
    }

    &.dragover {
      margin-bottom: 4px;
      padding-bottom: 4px;
      border-bottom: 2px solid $k-blue;
    }

    &.dragging.dragover {
      margin-bottom: 10px;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .droppable-scout-tag {
    height: 20px;
    min-height: 20px;

    &.dragover {
      margin-top: -5px;
      margin-bottom: 5px;
      border-bottom: 2px solid $k-blue;
    }
  }
</style>
