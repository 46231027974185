<template>
  <edit-modal
    :input="scoutTag"
    :title="modalTitle"
    :on-cancel="reset"
    :on-save="submitForm">
    <template #modal-trigger="modalProps">
      <div>
        <a href="" class="semibold-weight" @click.prevent="modalProps.openModal()">{{ triggerName }}</a>
      </div>
    </template>

    <template #default="{ inputCopy: scoutTagCopy }">
      <div class="row tight-columns bottom-30">
        <div class="col-sm-4">
          <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
            <label for="color">Color <span class="inline-help required">*</span></label>

            <dropdown-select-color
              ref="dropdownSelectColor"
              :initial-label="scoutTagCopy.color"
              id-label="color"
              v-model="scoutTagCopy.color"
              :colors="colors"
              :invalid="errors.length > 0">
            </dropdown-select-color>

            <div class="error-text top-5" v-if="errors.length">
              <error-message :errors="errors"></error-message>
            </div>
          </validation-provider>
        </div>

        <div class="col-sm-8 top-20-xs">
          <text-input-vertical
            ref="name"
            label="Label"
            input-name="name"
            id="name"
            rules="required"
            :maxlength="nameMaxlength"
            v-model="scoutTagCopy.name">
          </text-input-vertical>

          <div class="size-text-12px dark-gray-text top-5" v-if="showCharactersLimitedMessage(scoutTagCopy)">
            Limited to {{ nameMaxlength }} characters
          </div>
        </div>
      </div>
    </template>
  </edit-modal>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import DropdownSelectColor from 'vue-app/shared/components/dropdown-select-color.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import { ValidationProvider } from 'vee-validate';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import ScoutTag from 'vue-app/scout/resources/scout-tag.js';
import NotificationService from 'vue-app/shared/services/notification-service';

export default {
  name: 'ScoutTagEditingModal',

  components: {
    ErrorMessage,
    DropdownSelectColor,
    EditModal,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    scoutTag: {
      type: Object,
      required: true
    },

    scoutTags: {
      type: Array,
      default: () => []
    },

    onSaved: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      colors: ['blue', 'gray', 'green', 'purple', 'red', 'yellow'],
      nameMaxlength: 30
    };
  },

  computed: {
    triggerName() {
      return this.scoutTag.id ? 'Edit Tag' : '+ Add a Law Firm Tag';
    },

    modalTitle() {
      return this.scoutTag.id ? 'Edit Law Firm Tag' : 'Add Law Firm Tag';
    }
  },

  methods: {
    showCharactersLimitedMessage(scoutTagCopy) {
      return scoutTagCopy.name?.length >= this.nameMaxlength;
    },

    submitForm(scoutTagCopy) {
      if (this.scoutTag.id) {
        return this.updateScoutTag(scoutTagCopy);
      }
      else {
        return this.createScoutTag(scoutTagCopy);
      }
    },

    createScoutTag(scoutTagCopy) {
      return ScoutTag.save({ scoutTag: scoutTagCopy })
        .then(response => {
          this.onSaved(response);
          NotificationService.success('Law firm tag successfully saved');
        });
    },

    updateScoutTag(scoutTagCopy) {
      LoadingService.loading('save-button');

      return ScoutTag.update({ id: this.scoutTag.id, scoutTag: scoutTagCopy })
        .then(response => {
          this.onSaved(response);
          NotificationService.success('Law firm tag successfully updated');
        });
    },

    reset() {
      this.$refs.dropdownSelectColor.reset();
    }
  }
};
</script>
