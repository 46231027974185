<template>
  <priori-modal
    ref="modal"
    modal-id="scout-tag-delete-modal"
    title="Delete Tag">
    <template #modal-trigger="modalProps">
      <a href="" @click.prevent="modalProps.openModal()"><svg-icon name="trash" class="base-icon remove-tag-icon"></svg-icon></a>
    </template>

    <template #default>
      <div class="size-text-16px semibold-weight bottom-20">
        Delete this tag?
      </div>

      <div class="size-text-14px bottom-30">
        Note: Deleting a law firm tag permanently removes it from the law firm tags list, as well as all law firm profiles the tag was associated with.
      </div>

      <div class="row tight-columns">
        <div class="col-sm-4">
          <loading-button name="deleteTag" lb-class="primary-btn-blue" @lb-click="deleteTag">Yes</loading-button>
        </div>

        <div class="col-sm-3 top-20-xs">
          <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
        </div>
      </div>
    </template>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ScoutTag from 'vue-app/scout/resources/scout-tag.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import NotificationService from 'vue-app/shared/services/notification-service';

export default {
  name: 'ScoutTagDeleteModal',

  components: {
    PrioriModal,
    LoadingButton,
    SvgIcon
  },

  props: {
    scoutTag: {
      type:     Object,
      required: true
    },

    onDelete: {
      type:     Function,
      required: true
    }
  },

  methods: {
    deleteTag() {
      LoadingService.loading('deleteTag');
      ScoutTag.delete({ id: this.scoutTag.id })
      .then(() => { this.onDelete(); })
      .catch(error => { NotificationService.error(`Unable to save changes: ${error}`); })
      .finally(() => {
        this.closeModal();
        LoadingService.done('deleteTag');
      });
    },

    closeModal() {
      this.$refs.modal.dismissModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .remove-tag-icon {
    width: 16px;
    height: 16px;
    fill: $k-blue;
  }
</style>
